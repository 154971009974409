

















import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { ReferralViewModel } from '../viemmodel/referral-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject() vm!: ReferralViewModel

  get hasTier() {
    return this.vm.currentTier?.includes('TIER')
  }
}
